import _ from 'lodash-es';
import React, { useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import {
  Typography,
  Modal,
  Box,
  Button,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
} from '@mui/material';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { useConfirm } from 'material-ui-confirm';
import TemplatesApi from './Templates.api';
import TemplateContentForm from './TemplateContentForm';
import styleColors from '../colors.styles';
import { AlertsContext } from '../shared/alerts/Alerts.context';
import SpinningSpark from '../shared/components/SpinningSpark';

function createTopicsMap(topics) {
  const topicsMap = {};

  topics?.forEach((topic) => {
    topicsMap[topic.id] = topic;
  });

  return topicsMap;
}

const TEMPLATE_CATEGORIES = [{ id: 'pw', name: 'Pre-written' }];
const DEFAULT_TEMPLATE = {
  message: '',
  topic: '',
  subtopic: '',
  source: '',
  type: 'text',
  category: 'pw',
  created_source: 'desktop',
};

export default function TemplateCreateModal({ topics, closeModal, onCreate }) {
  const [template, setTemlate] = useState({ ...DEFAULT_TEMPLATE });
  const [startDatetime, setStartDatetime] = useState(null);
  const [endDatetime, setEndDatetime] = useState(null);
  const [evergreen, setEvergreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const confirm = useConfirm();
  const { showAlert } = useContext(AlertsContext);
  const topicsMap = useMemo(() => createTopicsMap(topics), [topics]);
  const subtopics = topicsMap[template.topic]?.subtopics ?? [];

  const validate = () => {
    const newErrors = {};
    const requiredFields = ['category', 'topic', 'subtopic', 'message'];

    requiredFields.forEach((key) => {
      if (!template[key]) {
        newErrors[key] = 'This field is required';
      }
    });

    if (!startDatetime) {
      newErrors.startDate = 'This field is required';
    }

    if (!evergreen) {
      if (!endDatetime) {
        newErrors.endDate = 'This field is required';
      } else {
        const timeDiff = endDatetime.diff(startDatetime, 'hours');
        if (timeDiff <= 0) {
          newErrors.endDate = 'End date should be after start date';
        }
      }
    }

    setErrors(newErrors);

    return _.isEmpty(newErrors);
  };

  const onSubmit = async () => {
    if (!validate()) {
      return;
    }

    setLoading(true);
    try {
      const templatePayload = {
        ...template,
        startDate: startDatetime.format(),
        endDate: evergreen ? null : startDatetime.format(),
      };

      await TemplatesApi.createTemplate(templatePayload);

      showAlert('Template created successfully!', 'success');
      closeModal();
      onCreate();
    } catch (err) {
      console.error(err);
      showAlert('Failed to create Template', 'error');
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    confirm({
      title: 'Are you sure you want to go back?',
      description: 'By closing this modal, you will lose your progress',
      confirmationText: 'Yes',
      cancellationText: 'No',
    })
      .then(closeModal)
      .catch(_.noop);
  };

  const renderError = (key) => {
    return (
      errors[key] && (
        <Typography variant="body2" color="red" paddingTop={1}>
          {errors[key]}
        </Typography>
      )
    );
  };

  const topicSelector = (
    <Box display="flex" flexDirection="row" gap={2.5}>
      <Box sx={styles.maxWidth}>
        <InputLabel style={{ fontWeight: 600, color: styleColors.base.black }}>
          Select Post Type
        </InputLabel>
        <Select
          fullWidth
          displayEmpty
          sx={styles.dropdown}
          value={template.category}
          onChange={(e) => {
            setTemlate((t) => ({
              ...t,
              category: e.target.value,
            }));
          }}
          renderValue={(selected) =>
            TEMPLATE_CATEGORIES.find((c) => c.id === selected)?.name
          }
        >
          {TEMPLATE_CATEGORIES.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
        {renderError('category')}
      </Box>

      <Box sx={styles.maxWidth}>
        <InputLabel style={{ fontWeight: 600, color: styleColors.base.black }}>
          Topic
        </InputLabel>
        <Select
          fullWidth
          displayEmpty
          sx={styles.dropdown}
          value={template.topic}
          onChange={(e) => {
            setTemlate((t) => ({
              ...t,
              topic: e.target.value,
              subtopic: '',
            }));
          }}
          renderValue={(selected) => topicsMap[selected]?.name || 'No topic'}
        >
          {topics.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
        {renderError('topic')}
      </Box>

      <Box sx={styles.maxWidth}>
        <InputLabel style={{ fontWeight: 600, color: styleColors.base.black }}>
          Suptopic
        </InputLabel>
        <Select
          fullWidth
          displayEmpty
          sx={styles.dropdown}
          value={template.subtopic}
          disabled={subtopics.length === 0}
          onChange={(e) => {
            setTemlate((t) => ({
              ...t,
              subtopic: e.target.value,
            }));
          }}
          renderValue={(selected) =>
            subtopics.find((p) => p.id === selected)?.name || 'No subtopic'
          }
        >
          {subtopics.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
          {subtopics.length === 0 && (
            <MenuItem disabled>No subtopics found</MenuItem>
          )}
        </Select>
        {renderError('subtopic')}
      </Box>
    </Box>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Modal open>
        <Box sx={styles.modalContent}>
          <Typography variant="h5" paddingBottom={4}>
            Create Template
          </Typography>

          <Box paddingBottom={1.5}>{topicSelector}</Box>

          <Box paddingBottom={3}>
            <TemplateContentForm
              template={template}
              onChange={setTemlate}
              messageError={renderError('message')}
            />
          </Box>

          {/* TODO: Targeting */}

          <Box paddingBottom={1}>
            <DatetimeSelector
              datetime={startDatetime}
              onChange={(newDate) => {
                setStartDatetime(newDate);
              }}
              dateLabel="Start Date"
              timeLabel="Start Time"
            />
            {renderError('startDate')}
          </Box>

          <FormControlLabel
            label="Evergreen"
            labelPlacement="end"
            control={
              <Checkbox
                color="primary"
                checked={evergreen}
                onChange={() => {
                  setEvergreen(!evergreen);
                }}
              />
            }
          />

          {!evergreen && (
            <Box paddingBottom={1}>
              <DatetimeSelector
                disabled={!startDatetime}
                datetime={endDatetime}
                onChange={setEndDatetime}
                minDatetime={
                  startDatetime ? moment(startDatetime).add(1, 'hour') : null
                }
                dateLabel="End Date"
                timeLabel="End Time"
              />
              {renderError('endDate')}
            </Box>
          )}

          {loading ? (
            <Box display="flex" justifyContent="center" paddingTop={2}>
              <SpinningSpark marginTop="0" />
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="flex-end"
              gap={2}
              paddingTop={2}
            >
              <Button
                variant="contained"
                color="secondary"
                style={{
                  ...styles.footerBtn,
                  backgroundColor: styleColors.base.inactive,
                  color: styleColors.base.black,
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  ...styles.footerBtn,
                  backgroundColor: styleColors.base.walmart_blue,
                }}
                onClick={onSubmit}
              >
                Create Template
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    </LocalizationProvider>
  );
}

const SELECTABLE_HOURS = ['AM', 'PM']
  .map((suffix) => {
    return _.range(0, 12).map((hour) => ({
      value: suffix === 'AM' ? hour : hour + 12,
      stringValue: `${hour === 0 ? 12 : hour}:00 ${suffix} CST`,
    }));
  })
  .flat();

const DatetimeSelector = ({
  datetime,
  minDatetime,
  onChange,
  dateLabel,
  timeLabel,
  disabled,
}) => {
  return (
    <Box display="flex" justifyContent="space-between" gap={3}>
      <Box sx={styles.maxWidth}>
        <InputLabel style={{ fontWeight: 600, color: styleColors.base.black }}>
          {dateLabel}
        </InputLabel>
        <DatePicker
          required
          disabled={disabled}
          variant="filled"
          sx={styles.datePicker}
          value={datetime}
          minDate={minDatetime}
          format="MMMM Do, YYYY"
          onChange={(event) => {
            let newDatetime = moment.tz(
              event.format('YYYY-MM-DD'),
              'America/Chicago'
            );

            // Ensure datetime is not before the minimum date
            if (minDatetime?.isAfter(newDatetime) ?? false) {
              newDatetime = moment(minDatetime);
            } else {
              // Keep the existing hour selected or default to 9 AM
              newDatetime.set('hours', datetime?.get('hours') ?? 9);
            }

            onChange(newDatetime);
          }}
        />
      </Box>

      <Box sx={styles.maxWidth}>
        <InputLabel style={{ fontWeight: 600, color: styleColors.base.black }}>
          {timeLabel}
        </InputLabel>
        <Select
          displayEmpty
          fullWidth
          disabled={!datetime || disabled}
          sx={styles.dropdown}
          value={datetime?.get('hours') ?? 9}
          onChange={(e) => {
            onChange(moment(datetime).set('hours', e.target.value));
          }}
          renderValue={(selected) =>
            SELECTABLE_HOURS.find((e) => e.value === selected)?.stringValue
          }
        >
          {SELECTABLE_HOURS.map((item) => {
            const isPastHour =
              minDatetime &&
              minDatetime.isSame(datetime, 'day') &&
              minDatetime.get('hours') > item.value;
            return (
              <MenuItem
                key={item.value}
                value={item.value}
                disabled={isPastHour}
              >
                {item.stringValue}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
    </Box>
  );
};

DatetimeSelector.propTypes = {
  datetime: PropTypes.instanceOf(moment),
  minDatetime: PropTypes.instanceOf(moment),
  onChange: PropTypes.func.isRequired,
  dateLabel: PropTypes.string.isRequired,
  timeLabel: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
DatetimeSelector.defaultProps = {
  datetime: null,
  minDatetime: null,
  disabled: false,
};

const styles = {
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    overflow: 'scroll',
    transform: 'translate(-50%, -50%)',
    maxHeight: '95vh',
    bgcolor: 'white',
    width: '683px',
    padding: 4,
    borderRadius: 4,
    boxShadow: 24,
  },
  inputLabel: {
    fontWeight: 600,
    color: styleColors.base.black,
  },
  dropdown: {
    flex: 1,
    height: 55,
    minWidth: 150,
    maxWidth: 250,
    backgroundColor: '#F6F9FC',
    borderRadius: 2,
    '& fieldset': { border: 'none' },
  },
  datePicker: {
    borderRadius: 2,
    width: '100%',
    backgroundColor: '#F6F9FC',
    '& fieldset': { border: 'none' },
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  footerBtn: {
    minHeight: '50px',
    borderRadius: 10,
    fontWeight: 600,
  },
  maxWidth: {
    width: '100%',
  },
};

TemplateCreateModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  topics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
