import AppService from '../shared/App.api';

export default {
  getTemplates,
  getTopics,
  uploadTemplateMedia,
  createTemplate,
};

const templatesUrl = '/admin/templates';
const topicsUrl = '/admin/topics';

async function getTemplates() {
  try {
    const response = await AppService.get(templatesUrl);
    return response.data.data;
  } catch (error) {
    throw new Error('Failed to get templates');
  }
}

async function getTopics() {
  try {
    const response = await AppService.get(topicsUrl);
    return response.data.data;
  } catch (error) {
    throw new Error('Failed to get topics');
  }
}

async function uploadTemplateMedia(file) {
  const formData = new FormData();
  formData.append('file', file);

  const response = await AppService.post('/publish/upload', formData);

  if (!response.status === 200 || !response.data.url) {
    throw new Error('Failed to upload template media', response);
  }

  return response.data.url;
}

async function createTemplate(template) {
  return AppService.post(templatesUrl, template);
}
