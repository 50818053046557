import React, { useEffect, useState } from 'react';

import { Box, Button, Stack, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import TemplatesApi from './Templates.api';
import styleColors from '../colors.styles';
import TemplateItem from './TemplateItem';
import SpinningSpark from '../shared/components/SpinningSpark';
import TemplatesFilters from './TemplatesFilters';
import TemplateCreateModal from './TemplateCreateModal';

export default function Templates() {
  const [templates, setTemplates] = useState([]);
  const [selectedtemplates, setSelectedTemplates] = useState([]);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({ postType: '' });
  const [modalOpen, setModalOpen] = useState(false);
  const postTypes = [
    { label: 'Pre-written', value: 'pw' },
    { label: 'Fill-in-the-Blank', value: 'fitb' },
    { label: 'Product Link', value: 'pl' },
  ];
  const [filterValues, setFilterValues] = useState({ postTypes });

  useEffect(() => {
    fetchFiltersAndTemplates();
  }, []);

  const fetchFiltersAndTemplates = async () => {
    setLoadingTemplates(true);
    try {
      const [topicsResponse, templatesResponse] = await Promise.all([
        TemplatesApi.getTopics(),
        TemplatesApi.getTemplates(),
      ]);

      setFilterValues((prev) => ({ ...prev, topics: topicsResponse }));
      setTemplates(templatesResponse);
      setSelectedTemplates(templatesResponse);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTemplates(false);
    }
  };

  const styles = {
    createButton: {
      backgroundColor: styleColors.base.walmart_blue,
      height: '50px',
      color: 'white',
      padding: '30px auto',
    },
    templatesContainer: {
      display: 'grid',
      gap: '20px',
      gridTemplateColumns: {
        xs: 'repeat(1, 1fr)',
        lg: 'repeat(2, 1fr)',
        xl: 'repeat(3, 1fr)',
      },
    },
  };

  const filterTemplates = (filters) => {
    let filtered = templates;
    if (filters.postType && filters.postType !== 'all') {
      filtered = filtered.filter(
        (template) => template.category === filters.postType
      );
    }
    if (filters.topic && filters.topic !== 'all') {
      filtered = filtered.filter(
        (template) => template.topic.id === filters.topic
      );
    }
    if (filters.subtopic && filters.subtopic !== 'all') {
      filtered = filtered.filter(
        (template) => template.subtopic.id === filters.subtopic
      );
    }

    setSelectedTemplates(filtered);
  };

  /**
   * Updates the applied filters and filters the templates accordingly.
   * If a search query is provided, it also applies the search filter.
   *
   * @param {string} searchQuery - The search term entered by the user.
   * @param {Object} [newFilters = appliedFilters] - The new filter values to apply, or use the current filters if none are provided..
   * @param {string} [newFilters.postType] - The selected post type filter.
   * @param {string} [newFilters.topic] - The selected topic filter.
   * @param {string} [newFilters.subtopic] - The selected subtopic filter.
   */
  const handleFilterChange = (searchQuery, newFilters = appliedFilters) => {
    setAppliedFilters(newFilters);
    filterTemplates(newFilters);
    if (searchQuery !== '') handleSearchChange(searchQuery);
  };

  function normalizeString(str) {
    return str
      .toLowerCase()
      .normalize('NFD') // split an accented letter in the base letter and the acent
      .replace(/[\u0300-\u036f]/g, ''); // remove all previously split accents
  }

  function handleSearchChange(searchQuery) {
    const normalizedQuery = normalizeString(searchQuery);
    setSelectedTemplates((prevTemplates) =>
      prevTemplates.filter(({ message }) =>
        normalizeString(message).includes(normalizedQuery)
      )
    );
  }

  return (
    <Stack spacing={4} paddingTop={3}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4">Templates</Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Add />}
          disabled={!filterValues.topics}
          style={styles.createButton}
          onClick={() => {
            setModalOpen(true);
          }}
        >
          Create Template
        </Button>
        {modalOpen && (
          <TemplateCreateModal
            topics={filterValues.topics}
            closeModal={() => setModalOpen(false)}
            onCreate={() => fetchFiltersAndTemplates()}
          />
        )}
      </Stack>
      <TemplatesFilters
        filterValues={filterValues}
        appliedFilters={appliedFilters}
        onFilterChange={handleFilterChange}
      />
      {loadingTemplates ? (
        <Box display="flex" justifyContent="center">
          <SpinningSpark isSpinning />
        </Box>
      ) : (
        <Box sx={styles.templatesContainer}>
          {selectedtemplates.map((template) => (
            <TemplateItem key={template.id} template={template} />
          ))}
        </Box>
      )}
    </Stack>
  );
}
