import React, { useState, useEffect } from 'react';
import {
  Box,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import SpinningSpark from '../shared/components/SpinningSpark';

export default function TemplatesFilters({
  filterValues,
  appliedFilters,
  onFilterChange,
}) {
  const { postTypes, topics } = filterValues;
  const [subtopics, setSubtopics] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    onFilterChange(searchQuery);
  }, [searchQuery]);

  return (
    <Box display="flex" gap={2} p={2}>
      <Select
        value={appliedFilters.postType || ''}
        onChange={(e) =>
          onFilterChange(searchQuery, {
            ...appliedFilters,
            postType: e.target.value,
          })
        }
        displayEmpty
        sx={inputStyles}
        renderValue={(selected) =>
          selected
            ? postTypes.find((p) => p.value === selected)?.label
            : 'Post Type'
        }
      >
        <MenuItem value="">All</MenuItem>
        {postTypes.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>

      <Select
        value={appliedFilters.topic || ''}
        onChange={(e) => {
          const selectedTopic = topics.find(
            (topic) => topic.id === e.target.value
          );
          setSubtopics(selectedTopic?.subtopics || []);
          onFilterChange(searchQuery, {
            ...appliedFilters,
            topic: e.target.value,
            subtopic: '',
          });
        }}
        displayEmpty
        sx={inputStyles}
        renderValue={(selected) =>
          selected ? topics.find((p) => p.id === selected)?.name : 'Topic'
        }
      >
        <MenuItem value="">All</MenuItem>
        {topics === undefined ? (
          <Box display="flex" justifyContent="center">
            <SpinningSpark isSpinning />
          </Box>
        ) : (
          topics.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))
        )}
      </Select>

      <Select
        value={appliedFilters.subtopic || ''}
        onChange={(e) =>
          onFilterChange(searchQuery, {
            ...appliedFilters,
            subtopic: e.target.value,
          })
        }
        displayEmpty
        sx={inputStyles}
        disabled={!appliedFilters.topic || subtopics.length === 0}
        renderValue={(selected) =>
          selected ? subtopics.find((p) => p.id === selected)?.name : 'Subtopic'
        }
      >
        <MenuItem value="">All</MenuItem>
        {subtopics.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>

      <TextField
        value={searchQuery}
        onChange={(event) => setSearchQuery(event.target.value)}
        placeholder="Search templates"
        variant="outlined"
        size="medium"
        sx={inputStyles}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {searchQuery ? (
                <CloseIcon
                  style={{ cursor: 'pointer', color: '#AEBDCC' }}
                  onClick={() => setSearchQuery('')}
                />
              ) : (
                <Search style={{ color: '#AEBDCC' }} />
              )}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}
const inputStyles = {
  flex: 1,
  minWidth: 150,
  height: 55,
  backgroundColor: '#F6F9FC',
  borderRadius: 2,
  '& fieldset': { border: 'none' },
};

TemplatesFilters.propTypes = {
  filterValues: PropTypes.shape({
    postTypes: PropTypes.arrayOf(PropTypes.shape({})),
    topics: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  appliedFilters: PropTypes.shape({
    postType: PropTypes.string,
    topic: PropTypes.string,
    subtopic: PropTypes.string,
  }).isRequired,
  onFilterChange: PropTypes.func.isRequired,
};
